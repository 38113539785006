<template>
  <div class="p1">
    <div class="mainTitle">{{ p1PropData.title }}</div>
    <div class="mainText">{{ p1PropData.text }}</div>
    <div class="mainContent">
      <div class="picBoxOne" v-if="p1PropData.picArr.length === 1">
        <img :src="p1PropData.picArr[0]" alt="" />
      </div>
      <div class="picBoxFour" v-else>
        <img :src="item" alt="" v-for="(item, index) in p1PropData.picArr" :key="index" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'p1',

  props: {
    p1PropData: {}
  }
}
</script>
<style lang="scss" scoped>
.p1 {
  overflow: hidden;
  width: 100%;
  // height: 880px;
  background: #fff;
  padding: 4.1vw 0 0 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  .mainTitle {
    font-family: HarmonyOS Sans SC;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;

    letter-spacing: 0em;

    color: #333333;
    margin-bottom: 40px;
  }
  .mainText {
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;

    letter-spacing: 0px;

    color: #333333;
    margin-bottom: 64px;
  }
  .mainContent {
    width: 100%;
    // height: 100%;
    // background: #ccc;
    .picBoxOne {
      img {
        width: 100%;
        height: 33.3vw;
      }
    }
    .picBoxFour {
      img {
        width: 25%;
        height: 33.3vw;
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .p1 {
    align-items: flex-start;
    .mainTitle {
      padding: 0 0 0 20px;
      font-family: HarmonyOS Sans SC;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      letter-spacing: 0em;

      color: #333333;
      margin-bottom: 20px;
    }
    .mainText {
      padding: 0 20px 0 20px;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;

      letter-spacing: 0px;

      color: #333333;
      margin-bottom: 20px;
    }
  }
}
@media only screen and (min-width: 1200px) {
}
</style>
