<template>
  <div class="p2">
    <div class="mainContent">
      <div class="leftBox">
        <!-- <div class="title">{{ p2PropData.title }}</div> -->
        <!-- <div class="title"> -->
        <i class="iconfont" :class="p2PropData.icon"></i>
        <!-- </div> -->
        <div class="text">{{ p2PropData.text }}</div>
        <div class="text">{{ p2PropData.text1 }}</div>
      </div>
      <div class="rightBox">
        <img class="productPic" :src="p2PropData.pic" alt="" />
        <img class="productPic-m" :src="p2PropData.pic2" :style="p2PropData.style" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'p2',

  props: {
    p2PropData: {}
  }
}
</script>
<style lang="scss" scoped>
.p2 {
  width: 100%;
  height: 720px;
  background: radial-gradient(121% 121% at 50% 50%, #333333 0%, #222222 100%);
  padding: 180px 0 0 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .mainContent {
    // background: #ccc;
    width: 1200px;
    height: 100%;
    position: relative;
    // display: flex;
    // justify-content: space-between;
    .leftBox {
      position: absolute;
      left: 0;
      top: 110px;
      .iconfont {
        font-size: 40px;
        color: #ffffff;
        line-height: 80px;
      }
      .title {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0em;

        color: #ffffff;
        margin-bottom: 30px;
      }
      .text {
        font-family: HarmonyOS Sans SC;
        font-size: 28px;
        font-weight: 300;
        line-height: 48px;
        letter-spacing: 0em;

        color: #ffffff;
      }
    }
    .rightBox {
      position: absolute;
      right: 0;
      top: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .p2 {
    .mainContent {
      .leftBox {
        .title {
        }
        .text {
        }
      }
      .rightBox {
        .productPic-m {
          display: none;
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .p2 {
    height: 100vh;
    padding: 0 0 0 0;
    .mainContent {
      width: 100%;
      height: 100%;
      position: relative;

      .leftBox {
        width: 90vw;
        position: absolute;
        left: 50%;
        top: 64%;
        transform: translate3d(-50%, -50%, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        .iconfont {
          font-size: 40px;
          color: #ffffff;
          line-height: 80px;
        }
        .title {
          font-family: HarmonyOS Sans SC;
          font-size: 40px;
          font-weight: 900;
          line-height: 40px;
          letter-spacing: 0em;

          color: #ffffff;
          margin-bottom: 20px;
        }
        .text {
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-weight: 300;
          line-height: 28px;
          letter-spacing: 0em;

          color: #ffffff;
        }
      }
      .rightBox {
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate3d(-50%, -50%, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        .productPic {
          display: none;
          width: 100%;
          height: 100%;
        }
        .productPic-m {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
