<template>
  <div class="contactFooter">
    <div class="mainContent">
      <!-- <div class="leftBox">
        <div class="leftTitle">联系我们</div>
        <input class="input1" type="text" placeholder="姓名" />
        <input class="input2" type="text" placeholder="联系方式" />
        <div class="submitButton">确认提交</div>
      </div> -->
      <div class="rightBox" v-if="type === 0">
        <div class="rightTitle">欢迎使用「耘尚农场」小程序</div>
        <img :src="require(`@/assets/images/commonUse/qrCode4.png`)" alt="" />
      </div>
      <div class="rightBox2" v-if="type === 1">
        <img :src="require(`@/assets/logo_sunyung1.png`)" alt="" />
        <div class="rightText">浙江省嘉善县罗星街道白水塘西路1918号宜葆科创中心</div>
        <div class="rightText">service@ecoprobio.cn</div>
        <div class="rightText">400-806-1696</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contactFooter',

  props: {
    type: {
      type: [Number],
      default: 0
    }
  }
}
</script>
<style lang="scss" scoped>
.contactFooter {
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  // height: 480px;
  background: #0e293b;
  padding: 80px 0 80px 0;
  box-sizing: border-box;
  display: flex;

  justify-content: center;
  align-items: center;
  .mainContent {
    width: 1200px;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    .leftBox {
      display: flex;
      flex-direction: column;
      .leftTitle {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0em;
        color: #ffffff;
        margin-bottom: 40px;
      }

      input {
        box-sizing: border-box;
        width: 400px;
        height: 60px;
        background: #0e293b;
        border: 1px solid #fff;
        color: #ffffff;
        font-size: 14px;
        font-weight: 300;
        padding: 0 0 0 24px;
        margin-bottom: 20px;
      }
      input::input-placeholder {
        color: #fff;
      }
      input::-webkit-input-placeholder {
        //兼容WebKit browsers（Chrome的内核）
        color: #fff;
      }
      input::-moz-placeholder {
        //Mozilla Firefox 4 to 18
        color: #fff;
      }
      input::-moz-placeholder {
        //Mozilla Firefox 19+
        color: #fff;
      }
      input::-ms-input-placeholder {
        //Internet Explorer 10+
        color: #fff;
      }

      .submitButton {
        margin-top: 20px;
        width: 400px;
        height: 60px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 300;
        line-height: 14px;
        letter-spacing: 0px;

        color: #333333;
        cursor: pointer;
      }
    }
    .rightBox {
      display: flex;
      flex-direction: column;
      // align-items: flex-end;
      align-items: center;
      .rightTitle {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0em;
        color: #ffffff;
        margin-bottom: 40px;
      }
      img {
        width: 240px;
        height: 240px;
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .mainContent {
      width: 100%;
      flex-direction: column;
      align-items: center;
      .leftBox {
        .leftTitle {
          font-size: 20px;
          font-weight: 600;
          line-height: 20px;

          margin-bottom: 20px;
        }
        input {
          width: 335px;
          height: 40px;
          font-size: 14px;
          font-weight: 300;
          padding: 0 0 0 12px;
          margin-bottom: 12px;
        }
        .submitButton {
          width: 335px;
          height: 40px;
        }
      }
      .rightBox {
        // margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .rightTitle {
          font-family: HarmonyOS Sans SC;
          font-size: 20px;
          font-weight: 900;
          line-height: 20px;
          letter-spacing: 0em;
          color: #ffffff;
          margin-bottom: 20px;
        }
        img {
          width: 120px;
          height: 120px;
        }
      }
      .rightBox2 {
        // margin-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 88.2px;
          height: 32px;
        }
        .rightText {
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-weight: 300;
          line-height: 14px;
          letter-spacing: 0em;
          color: #ffffff;
          margin-top: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .contactFooter {
    padding: 40px 20px 40px 20px;
  }
}
</style>
