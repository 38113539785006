<template>
  <div class="videoModal">
    <div class="videoModalArea">
      <div class="backLine">
        <i class="iconfont icon-btn_close1" @click="$emit('close')"></i>
      </div>
      <iframe
        id="video_box"
        frameborder="0"
        :src="videoUrl"
        :allowFullScreen="true"
        :autoplay="true"
        :muted="true"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'videoModal',

  props: {
    videoUrl: ''
  }
}
</script>
<style lang="scss" scoped>
.videoModal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  z-index: 101;
  .videoModalArea {
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // background-color: red;
    // padding: 20px;
    z-index: 102;
    width: 100%;
    height: 100vh;
    .backLine {
      display: flex;
      align-items: center;
      padding-left: 5vw;
      color: #fff;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);

      width: 100%;
      height: 7vh;
      z-index: 103;
    }
    #video_box {
      z-index: 102;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
    }
  }
}
</style>
