<template>
  <!-- type  0环境通   2智控中心  3综合气象站 1水质监测 4采收宝-->
  <div class="product">
    <navHeader :index="2" :idx="navHeaderIdx"></navHeader>
    <!-- 顶部大标题栏 -->
    <p2 :p2PropData="p2PropData"></p2>
    <!-- 可监控参数 -->
    <div
      class="parameterBox"
      v-if="this.$route.query.type == 0 || this.$route.query.type == 1 || this.$route.query.type == 3"
    >
      <div class="parameterTitle" v-if="this.$route.query.type == 0">可选型号</div>
      <img class="parameterPic3" v-if="this.$route.query.type == 0" :src="parameterArr3[1]" alt="" />
      <img class="parameterPic3-m" v-if="this.$route.query.type == 0" :src="parameterArr3[0]" alt="" />
      <div
        class="parameterTitle"
        v-if="this.$route.query.type == 0 || this.$route.query.type == 3 || this.$route.query.type == 1"
      >
        可监测参数
      </div>
      <img
        v-if="this.$route.query.type == 0 || this.$route.query.type == 1"
        class="parameterPic"
        :src="parameterArr[this.$route.query.type]"
        alt=""
      />
      <img
        class="parameterPicType3"
        v-if="this.$route.query.type == 3"
        :src="parameterArr[this.$route.query.type]"
        alt=""
      />
      <img
        class="parameterPic1-m"
        v-if="this.$route.query.type == 0 || this.$route.query.type == 1"
        :src="parameterArr2[this.$route.query.type]"
        alt=""
      />
      <img
        class="parameterPic2-m"
        v-if="this.$route.query.type == 3"
        :src="parameterArr2[this.$route.query.type]"
        alt=""
      />
    </div>
    <div class="parameterBox2" v-if="this.$route.query.type == 2">
      <div class="content">
        <img class="productPic" :src="require(`@/assets/images/product/product5Detail.png`)" alt="" />

        <div class="imgBox">
          <div class="imgBoxTitle">智控宝</div>
          <img class="parameterPic" :src="require(`@/assets/images/product/parameter6.png`)" alt="" />

          <img class="parameterPic-m" :src="require(`@/assets/images/product/parameter6-m.png`)" alt="" />
        </div>
      </div>
      <div class="content">
        <img class="productPic" :src="require(`@/assets/images/product/product3Detail.png`)" alt="" />

        <div class="imgBox">
          <div class="imgBoxTitle">智控中心</div>
          <img class="parameterPic" :src="parameterArr[this.$route.query.type]" alt="" />

          <img class="parameterPic-m" :src="parameterArr2[this.$route.query.type]" alt="" />
        </div>
      </div>
    </div>
    <!-- 多场景应用 -->
    <p1 :p1PropData="p1PropData" v-if="this.$route.query.type == 0 || this.$route.query.type == 1"></p1>
    <!-- 可控制设备 -->
    <div class="devices" v-if="this.$route.query.type == 2">
      <img class="devicesBg" :src="require(`@/assets/images/product/bg.png`)" alt="" />

      <div class="devicesContent">
        <div class="devicesTitle">可控制设备</div>
        <div class="devicesPic">
          <div class="devicesPicItem" v-for="(dItem, dIndex) in devicesPicArr" :key="dIndex">
            <img class="devicesBg" :src="dItem" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="devices-m" v-if="this.$route.query.type == 2">
      <div class="devicesTitle">可控制设备</div>
      <img class="devicesBg-m" :src="require(`@/assets/images/product/bg1.png`)" alt="" />
      <div class="devicesContent">
        <div class="devicesPic">
          <div class="devicesPicItem" v-for="(dItem, dIndex) in devicesPicArr" :key="dIndex">
            <img class="devicesBg" :src="dItem" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 综合气象站-贴合生产 -->
    <div class="weatherDetail" v-if="this.$route.query.type == 3">
      <div class="weatherDetailContent">
        <img class="farm1" :src="require(`@/assets/images/product/farm1.png`)" alt="" />
        <div class="farm1DetailBoxTitle">贴合生产</div>
        <div class="farm1DetailBoxText">
          专为中国农业环境量身定制，通过尚阳大数据分析，根据气候环境结合土壤肥力和作物生长状况的空间差异，调节对作物的投入，实现优质、高产、低耗的可持续农业。
        </div>
        <img class="farm2" :src="require(`@/assets/images/product/farm2.png`)" alt="" />
        <div class="farm2DetailBoxTitle">精确测量</div>
        <div class="farm2DetailBoxText">
          精准测量监测区域空气温湿度、土壤温湿度、风速风向、大气压、光照等关键环境数据，通过网络实时同步到云端，形成历史沉淀，支撑范围内的环境预警。
        </div>
      </div>
    </div>
    <!-- 采收宝-核心功能 -->
    <div class="coreFunctions" v-if="this.$route.query.type == 4">
      <div class="coreFunctionsTitle">核心功能</div>
      <div class="coreFunctionsContent">
        <img class="coreFunctionsContent-L" :src="require(`@/assets/images/product/csb0.png`)" alt="" />
        <img class="coreFunctionsContent-L-M" :src="require(`@/assets/images/product/csb0-m.png`)" alt="" />
        <div class="coreFunctionsContent-R">
          <div class="function-R-item" v-for="(item, index) in coreFunctions" :key="index">
            <div class="iconBox">
              <!-- <img :src="item.src" alt="" /> -->
              <i class="iconfont iconStyle" :class="item.icon"></i>
              <i class="iconfont iconStyle-m" :class="item.icon"></i>
            </div>
            <div class="r-box">
              <div class="r-box-title">{{ item.functionTitle }}</div>
              <div class="r-box-text">{{ item.functionText }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 采收宝-采收宝能做到 -->
    <div class="csbCan" v-if="this.$route.query.type == 4">
      <div class="canInside">
        <div class="canInsideTitle">采收宝能做到</div>
        <div class="canInsideText">
          采收宝是一套针对传统浆果类采收、包装及入库的全流程解决方案。我们有智能化的称重外设，响应快、续航长、支持弱网存储数据，一台设备可覆盖100+亩的称重需求;同时，我们还有功能丰富的软件支撑，支持用户自定义所属的农场数据，如人员工号、编码规则、作物维护、工资设定等功能，并提供小程序供管理层查看多维度的农场数据，掌握生产状况。
        </div>
        <div class="canPicArea">
          <img class="canPicAreaPC" :src="require(`@/assets/images/product/csb7.png`)" alt="" />
          <img class="canPicAreaM" :src="require(`@/assets/images/product/csb7-m.png`)" alt="" />

          <!-- <div class="canPicAreaItem" v-for="(item, index) in csbCanList" :key="index">
            <img :src="item.src" alt="" />
            <div class="itemTitle">{{ item.functionTitle }}</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 软件支持 -->
    <div class="softwareBox">
      <div class="softwareInside">
        <div class="softwareSupport" v-if="this.$route.query.type != 4">
          <div class="softwareSupportTitle">软件支持</div>
          <div class="softwareSupportText">我们提供微信小程序及PC端管理后台，让您可以足不出户查看作物的生长环境</div>
          <div class="softwareSupportPicBox">
            <div class="iphone">
              <img :src="require(`@/assets/images/product/iphone1.png`)" alt="" />
              <img :src="require(`@/assets/images/product/iphone2.png`)" alt="" />
              <img :src="require(`@/assets/images/product/iphone3.png`)" alt="" />
            </div>

            <img class="pc" :src="require(`@/assets/images/product/pc1.png`)" alt="" />
          </div>
        </div>
        <div class="softwareFunction">
          <div class="softwareFunctionTitle">软件功能</div>
          <div class="softwareFunctionBoxWrapper">
            <div class="softwareFunctionBox" v-for="(item, index) in softwareFunction" :key="index">
              <img :src="item.src" style="width: 32.1px; height: 32px" alt="" />
              <div class="functionText">{{ item.functionText }}</div>
            </div>
          </div>
        </div>
        <div class="softwareVideo" v-if="this.$route.query.type == 2">
          <div
            class="videoList"
            v-for="(vItem, vIndex) in videoList"
            :key="vIndex"
            :style="vItem.style"
            @click="videoMobileClick(vItem.src)"
          >
            <iframe id="fram_box" frameborder="0" :src="vItem.src" allowFullScreen="true"></iframe>

            <!-- <img
              class="playBtn"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              style="width: 7.46vw; height: 7.46vw"
              alt=""
            /> -->
          </div>
          <div
            class="videoList-m"
            v-for="(vItem1, vIndex1) in videoList"
            :key="vIndex1 + 10"
            :style="vItem1.styleM"
            @click="videoMobileClick(vItem1.src)"
          >
            <img class="coverPic" :src="vItem1.coverPic" :style="vItem1.styleM" alt="" />
            <img
              class="playBtn"
              :src="require(`@/assets/images/commonUse/btnPlay.png`)"
              style="width: 7.46vw; height: 7.46vw"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="advantage" v-if="this.$route.query.type == 0 || this.$route.query.type == 1">
      <div class="advantageInside">
        <img
          class=""
          v-if="this.$route.query.type == 0"
          :src="require(`@/assets/images/product/advantage1.png`)"
          alt=""
        />
        <img
          class=""
          v-if="this.$route.query.type == 1"
          :src="require(`@/assets/images/product/advantage2.png`)"
          alt=""
        />
        <div class="advantageDetail">
          <div class="advantageDetailBox">
            <div class="advantageDetailBoxTitle">功能全面</div>
            <div class="advantageDetailBoxText">
              动态视频监测和环境监测，集成多种环境传感器（空气温湿度、光照、土壤温湿度、EC值/PH值）
            </div>
          </div>
          <div class="advantageDetailBox">
            <div class="advantageDetailBoxTitle">简单易用</div>
            <div class="advantageDetailBoxText">即插（挂）即用，可接电或太阳能供电，4G网络，扫码即用</div>
          </div>
          <div class="advantageDetailBox">
            <div class="advantageDetailBoxTitle">适用性广</div>
            <div class="advantageDetailBoxText">形态多样，可用于标准薄膜大棚、连栋大棚以及大田</div>
          </div>
          <div class="advantageDetailBox">
            <div class="advantageDetailBoxTitle">低功耗</div>
            <div class="advantageDetailBoxText">低功耗节能设计，内置电池，弱光环境可连续工作15天以上</div>
          </div>
        </div>
      </div>
    </div>
    <contactFooter></contactFooter>
    <rightsFooter></rightsFooter>
    <videoModal :videoUrl="videoUrlMobile" v-if="isShowPopup" @close="closePopup"></videoModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navHeader from '@/components/navHeader.vue'
import contactFooter from '@/components/contactFooter.vue'
import p1 from '@/components/p1.vue'
import p2 from '@/components/p2.vue'
import videoModal from '@/components/videoModal.vue'
import rightsFooter from '@/components/rightsFooter.vue'

export default {
  name: 'Product',
  components: {
    navHeader,
    contactFooter,
    p1,
    p2,
    videoModal,
    rightsFooter
  },
  data() {
    return {
      isShowPopup: false,
      videoUrlMobile: '',
      videoList: [
        {
          src: 'https://v.qq.com/txp/iframe/player.html?vid=g3510g3acrf',
          coverPic: require(`@/assets/images/product/videoCover1.png`),
          styleM: 'width:33.3vw;height:50vw',
          style: 'width:360px;height:540px'
        },
        {
          src: 'https://v.qq.com/txp/iframe/player.html?vid=y3510ief90v',
          coverPic: require(`@/assets/images/product/videoCover2.png`),
          styleM: 'width:33.3vw;height:50vw',
          style: 'width:360px;height:540px'
        },
        {
          src: 'https://v.qq.com/txp/iframe/player.html?vid=l3510di5zu1',
          coverPic: require(`@/assets/images/product/videoCover3.png`),
          styleM: 'width:33.3vw;height:50vw',
          style: 'width:360px;height:540px'
        }
      ],

      devicesPicArr: [
        require(`@/assets/images/product/device1.png`),
        require(`@/assets/images/product/device2.png`),
        require(`@/assets/images/product/device3.png`),
        require(`@/assets/images/product/device4.png`),
        require(`@/assets/images/product/device5.png`),
        require(`@/assets/images/product/device6.png`),
        require(`@/assets/images/product/device7.png`)
      ],
      parameterArr: [
        require(`@/assets/images/product/parameter1.png`),
        require(`@/assets/images/product/parameter2.png`),
        require(`@/assets/images/product/parameter3.png`),
        require(`@/assets/images/product/parameter4.png`)
      ],
      parameterArr2: [
        require(`@/assets/images/product/parameter1-m.png`),
        require(`@/assets/images/product/parameter2-m.png`),
        require(`@/assets/images/product/parameter3-m.png`),
        require(`@/assets/images/product/parameter4-m.png`)
      ],
      parameterArr3: [
        require(`@/assets/images/product/parameter5-m.png`),
        require(`@/assets/images/product/parameter5.png`)
      ],
      p2PropDataArr: [
        {
          title: '环境通',
          icon: 'icon-huanjingtong',
          text: '实时监测小生态中的空气环境和土壤环境',
          text1: '环境智能预警与提醒',
          pic: require(`@/assets/images/product/product1.png`),
          style: 'width:34vw;height:56.8vw',
          pic2: require(`@/assets/images/product/product1x2.png`)
        },
        {
          title: '水质监测浮漂',
          icon: 'icon-shuizhijiancefupiao',
          text: '水温、EC、PH、溶解氧、余氯等水质环境实时监测',
          text1: '水质异常提醒 联动增氧、投料等设备',
          pic: require(`@/assets/images/product/product2.png`),
          style: 'width:54.53vw;height:56.8vw',
          pic2: require(`@/assets/images/product/product2x2.png`)
        },
        {
          title: '智控系列',
          icon: 'icon-zhikongxilie',
          text: '将您的农业设备(卷帘、灌溉、补光灯、水泵等)智能化',
          text1: '让设施控制更精准，管理更高效',
          pic: require(`@/assets/images/product/product3.png`),
          style: 'width:60.26vw;height:56.8vw',
          pic2: require(`@/assets/images/product/product3.png`)
        },
        {
          title: '综合气象站',
          icon: 'icon-zongheqixiangzhan',
          text: '实时监测区域气象、土壤、水质等信息',
          text1: '气象灾害预警 历史区域数据分析',
          pic: require(`@/assets/images/product/product4.png`),
          style: 'width:27.47vw;height:72vw',
          pic2: require(`@/assets/images/product/product4x2.png`)
        },
        {
          title: '采收宝',
          icon: 'icon-caishoubao',
          text: '智能化的称重外设，响应快、续航长、支持弱网',
          text1: '存储数据，一台设备可覆盖100+亩的称重需求',
          pic: require(`@/assets/images/product/product5.png`),
          style: 'width:67.64vw;height:56.8vw',
          pic2: require(`@/assets/images/product/product5x2.png`)
        }
      ],
      p2PropData: {},
      p1PropData: {},
      p1PropDataArr: [
        {
          title: '多场景应用',
          text: '适配多种传感器，精准获取各类环境参数， 可适用于大田、温室、果园、无土栽培等场景。',
          picArr: [
            require(`@/assets/images/product/scene1.png`),
            require(`@/assets/images/product/scene2.png`),
            require(`@/assets/images/product/scene3.png`),
            require(`@/assets/images/product/scene4.png`)
          ]
        },
        {
          title: '监测与控制',
          text: '控温、供氧、投料、监测水质',
          picArr: [require(`@/assets/images/product/scene5.png`)]
        }
      ],
      navHeaderIdx: 0,
      coreFunctions: [
        {
          functionTitle: '采收称重 &冞收数据汇总',
          functionText: '工人即采即称，数据即称即存，大大提高工作效率',
          src: require(`@/assets/images/product/icon13.png`),
          icon: 'icon-ic_weight'
        },
        {
          functionTitle: '用工统计 &工资计算及报表',
          functionText: '统计精确到每天每人每次，支撑灵活设置工资策略',
          src: require(`@/assets/images/product/icon14.png`),
          icon: 'icon-ic_calendar'
        },
        {
          functionTitle: '人员管理',
          functionText: '管理所属员工，制作对应工牌',
          src: require(`@/assets/images/product/icon15.png`),
          icon: 'icon-ic_personnel_index'
        },
        {
          functionTitle: '基地管理',
          functionText: '管理基地数据，编码规则，地块维护等，可高度自定义',
          src: require(`@/assets/images/product/icon16.png`),
          icon: 'icon-ic_base_farm'
        },
        {
          functionTitle: '数据分析',
          functionText: '采收数据、商品果率、次果率、株产量等',
          src: require(`@/assets/images/product/icon17.png`),
          icon: 'icon-ic_statistics_tray'
        }
      ],
      // csbCanList: [
      //   {
      //     functionTitle: '数据多维度',

      //     src: require(`@/assets/images/product/csb1.png`)
      //   },
      //   {
      //     functionTitle: '流程可追溯',

      //     src: require(`@/assets/images/product/csb2.png`)
      //   },
      //   {
      //     functionTitle: '生产无纸化',

      //     src: require(`@/assets/images/product/csb3.png`)
      //   },
      //   {
      //     functionTitle: '人员易管理',

      //     src: require(`@/assets/images/product/csb4.png`)
      //   },
      //   {
      //     functionTitle: '质检入库管理',

      //     src: require(`@/assets/images/product/csb5.png`)
      //   },
      //   {
      //     functionTitle: '库存打托管理',

      //     src: require(`@/assets/images/product/csb6.png`)
      //   }
      // ],
      softwareFunction: [],
      softwareFunction1: [
        {
          functionText: '种植难点会诊',
          src: require(`@/assets/images/product/icon1.png`)
        },
        {
          functionText: '核心环境监测',
          src: require(`@/assets/images/product/icon2.png`)
        },
        {
          functionText: '异常环境预警',
          src: require(`@/assets/images/product/icon3.png`)
        },
        {
          functionText: '种植过程记录',
          src: require(`@/assets/images/product/icon4.png`)
        },
        {
          functionText: '种植经验沉淀',
          src: require(`@/assets/images/product/icon5.png`)
        },
        {
          functionText: '农场智能管理',
          src: require(`@/assets/images/product/icon6.png`)
        }
      ],
      softwareFunction2: [
        {
          functionText: '称重记录',
          src: require(`@/assets/images/product/icon7.png`)
        },
        {
          functionText: '基地管理',
          src: require(`@/assets/images/product/icon8.png`)
        },
        {
          functionText: '用工报表',
          src: require(`@/assets/images/product/icon9.png`)
        },
        {
          functionText: '质检入库',
          src: require(`@/assets/images/product/icon10.png`)
        },
        {
          functionText: '打托出库',
          src: require(`@/assets/images/product/icon11.png`)
        },
        {
          functionText: '可视化数据',
          src: require(`@/assets/images/product/icon12.png`)
        }
      ]
    }
  },
  computed: {},
  created() {
    this.p2PropData = this.p2PropDataArr[this.$route.query.type]
    if (this.$route.query.type == 0 || this.$route.query.type == 1) {
      this.p1PropData = this.p1PropDataArr[this.$route.query.type]
    }
    if (this.$route.query.type == 4) {
      this.navHeaderIdx = 13
      this.softwareFunction = this.softwareFunction2
    } else {
      this.navHeaderIdx = parseInt(this.$route.query.type) + 7
      this.softwareFunction = this.softwareFunction1
    }
  },
  mounted() {},
  methods: {
    videoMobileClick(e) {
      this.isShowPopup = true
      this.videoUrlMobile = e
    },
    openPopup() {
      this.isShowPopup = true
    },
    closePopup() {
      this.isShowPopup = false
    }
  }
}
</script>
<style lang="scss" scoped>
.product {
  // padding-bottom: 100px;
  .parameterBox {
    padding: 0 0 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f2f3ee;
  }
  @media only screen and (max-width: 1199px) {
    .parameterBox {
      padding: 0 0 5.33vw 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f2f3ee;
      .parameterPic {
        display: none;
      }
      .parameterPicType3 {
        display: none;
      }
      .parameterPic3 {
        display: none;
      }
      .parameterTitle {
        margin: 5.33vw 0 2.7vw 0;
        font-family: PingFang SC;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
      }
      .parameterPic3-m {
        width: 89.3vw;
        height: 165.7vw;
      }
      .parameterPic1-m {
        width: 89.3vw;
        height: 98.4vw;
      }
      .parameterPic2-m {
        width: 89.3vw;
        height: 179.2vw;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .parameterBox {
      .parameterPic {
        width: 1200px;
        height: 324px;
      }
      .parameterTitle {
        margin: 80px 0 64px 0;
        font-size: 40px;
        font-weight: 900;
        color: #333333;
      }
      .parameterPicType3 {
        width: 1200px;
        height: 519px;
      }
      .parameterPic3 {
        width: 1200px;
        height: 844px;
      }
      .parameterPic3-m {
        display: none;
      }
      .parameterPic1-m {
        display: none;
      }
      .parameterPic2-m {
        display: none;
      }
    }
  }

  .parameterBox2 {
    padding: 80px 0 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .content {
      padding-bottom: 40px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      .productPic {
        width: 580px;
        height: 580px;
      }
      .imgBox {
        width: 580px;
        height: 580px;
        display: flex;
        flex-direction: column;
        padding-left: 80px;
        box-sizing: border-box;
        justify-content: center;

        background: #f2f3ee;
        .imgBoxTitle {
          font-family: HarmonyOS Sans SC;
          font-size: 40px;
          font-weight: 900;
          line-height: 40px;
          letter-spacing: 0em;
          margin-bottom: 40px;
          color: #333333;
        }
        .parameterPic {
          width: 212px;
          height: 336px;
        }
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    .parameterBox2 {
      padding: 40px 40px 40px 40px;
      display: flex;

      align-items: center;
      justify-content: center;
      .content {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        .productPic {
          width: 78.67vw;
          height: 78.67vw;
        }
        .imgBox {
          width: 78.67vw;
          height: 83.2vw;
          display: flex;
          // align-items: center;
          justify-content: center;
          background: #f2f3ee;
          padding-left: 5.33vw;
          .imgBoxTitle {
            font-family: HarmonyOS Sans SC;
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            margin-bottom: 5.33vw;
            color: #0e293b;
          }
          .parameterPic {
            display: none;
          }
          .parameterPic-m {
            width: 47.6vw;
            height: 51.2vw;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .parameterBox2 {
      .content {
        .productPic {
        }
        .imgBox {
          .parameterPic {
          }
          .parameterPic-m {
            display: none;
          }
        }
      }
    }
  }

  .coreFunctions {
  }
  @media only screen and (max-width: 1199px) {
    .coreFunctions {
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      background: #f2f3ee;
      padding: 0 10.6vw 0 10.6vw;
      .coreFunctionsTitle {
        margin: 10.6vw 0 5.3vw 0;
        font-size: 20px;
        font-weight: 900;
        color: #333333;
      }
      .coreFunctionsContent {
        display: flex;
        flex-direction: column;
        align-items: center;

        box-sizing: border-box;
        .coreFunctionsContent-L {
          display: none;
        }
        .coreFunctionsContent-L-M {
          width: 78.67vw;
          // height: 714px;
          // border-radius: 64px;
          margin-bottom: 7.46vw;
        }
        .coreFunctionsContent-R {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .function-R-item {
            display: flex;
            align-items: center;
            margin-bottom: 5.3vw;
            .iconBox {
              width: 19.2vw;
              height: 19.2vw;
              border-radius: 2.13vw;
              background: rgba(51, 51, 51, 0.05);
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 4.26vw;
              .iconStyle {
                display: none;
              }
              .iconStyle-m {
                font-size: 36px;
              }
            }
            .r-box {
              width: 55.2vw;
              .r-box-title {
                font-family: HarmonyOS Sans SC;
                font-size: 14px;
                font-weight: bold;
                line-height: 14px;
                letter-spacing: 0em;

                font-variation-settings: 'opsz' auto;
                color: #222449;
                margin-bottom: 16px;
              }
              .r-box-text {
                font-family: HarmonyOS Sans SC;
                font-size: 14px;
                font-weight: 300;
                line-height: 14px;
                letter-spacing: 0em;

                color: #666666;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .coreFunctions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #f2f3ee;
      .coreFunctionsTitle {
        margin: 80px 0 64px 0;
        font-size: 40px;
        font-weight: 900;
        color: #333333;
      }
      .coreFunctionsContent {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        padding: 0px 80px 80px 80px;
        box-sizing: border-box;
        .coreFunctionsContent-L-M {
          display: none;
        }
        .coreFunctionsContent-R {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .function-R-item {
            display: flex;
            align-items: center;
            .iconBox {
              width: 80px;
              height: 80px;
              border-radius: 16px;
              background: rgba(51, 51, 51, 0.05);
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 28px;
              .iconStyle {
                font-size: 40px;
              }
              .iconStyle-m {
                display: none;
              }
            }
            .r-box {
              .r-box-title {
                font-family: HarmonyOS Sans SC;
                font-size: 14px;
                font-weight: bold;
                line-height: 14px;
                letter-spacing: 0em;

                font-variation-settings: 'opsz' auto;
                color: #222449;
                margin-bottom: 16px;
              }
              .r-box-text {
                font-family: HarmonyOS Sans SC;
                font-size: 14px;
                font-weight: 300;
                line-height: 14px;
                letter-spacing: 0em;

                color: #666666;
              }
            }
          }
        }
      }
    }
  }
  .devices {
    position: relative;
    width: 100%;
    height: 840px;
    // background: #666666;
    // background-image: url('~@/assets/images/product/bg.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    .devicesBg {
      width: 100%;
      height: 840px;
      object-fit: cover;
    }
    .devicesBg-m {
      display: none;
    }
    .devicesContent {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background: #666666;
      .devicesTitle {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;
        // text-align: center;
        letter-spacing: 0em;

        color: #ffffff;
        margin-bottom: 40px;
      }
      .devicesPic {
        display: flex;
        flex-wrap: wrap;
        opacity: 0.85;

        background: #ffffff;
        width: 1200px;
        height: 600px;
        .devicesPicItem {
          width: 300px;
          height: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
            height: 150px;
          }
        }
      }
    }
  }
  .devices-m {
    .devicesTitle {
      padding: 0 5.33vw 0 5.33vw;
      font-family: HarmonyOS Sans SC;
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      // text-align: center;
      letter-spacing: 0em;

      color: #333333;
      margin-bottom: 20px;
    }
    .devicesBg-m {
      width: 100%;
      height: 33vw;
    }
    .devicesContent {
      margin-top: 5.33vw;
      margin-bottom: 5.33vw;
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // background: #666666;
      // padding: 0 5.33vw 0 5.33vw;
      // background: #f2f3ee;
      .devicesPic {
        // padding: 0 5.33vw 0 5.33vw;
        display: flex;
        flex-wrap: wrap;
        opacity: 0.85;
        background: #f2f3ee;
        // background: #ffffff;

        width: 89.3vw;
        height: 178.7vw;
        box-sizing: border-box;
        .devicesPicItem {
          width: 44.65vw;
          height: 44.65vw;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
            height: 20vw;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .devices {
      display: none;
    }
  }
  @media only screen and (min-width: 1200px) {
    .devices-m {
      display: none;
    }
  }
  .weatherDetail {
    width: 100%;
    height: 1240px;
    // background: #ccc;

    display: flex;
    justify-content: center;
    overflow: hidden;
    .weatherDetailContent {
      width: 1200px;
      height: 100%;
      position: relative;
      // background: #ccc;
    }
    .farm1 {
      position: absolute;
      top: 80px;
      right: 432px;
      // width: 960px;
      // height: 540px;
      width: 768px;
      height: 432px;
    }
    .farm1DetailBoxTitle {
      position: absolute;
      top: 228px;
      // left: 720px;
      left: 860px;
      font-family: HarmonyOS Sans SC;
      font-size: 32px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0em;

      color: #222449;
    }
    .farm1DetailBoxText {
      width: 340px;
      position: absolute;
      top: 280px;
      // left: 720px;
      left: 860px;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      text-align: justify; /* 浏览器可能不支持 */
      letter-spacing: 0em;

      color: #222449;
    }
    .farm2 {
      position: absolute;
      top: 700px;
      left: 432px;
      // width: 960px;
      // height: 540px;
      width: 768px;
      height: 432px;
    }
    .farm2DetailBoxTitle {
      position: absolute;
      top: 848px;
      left: 0vw;
      font-family: HarmonyOS Sans SC;
      font-size: 32px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0em;

      color: #222449;
    }
    .farm2DetailBoxText {
      width: 340px;
      position: absolute;
      top: 900px;
      left: 0vw;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      text-align: justify; /* 浏览器可能不支持 */
      letter-spacing: 0em;

      color: #222449;
    }
  }
  @media only screen and (max-width: 1199px) {
    .weatherDetail {
      width: 100%;
      height: 205vw;
      display: flex;
      justify-content: center;
      align-items: center;
      .weatherDetailContent {
        width: 89.33vw;
        height: 195vw;
        position: relative;
      }
      .farm1 {
        position: absolute;
        top: 10.6vw;
        left: 0vw;
        width: 89.33vw;
        height: 50.13vw;
      }
      .farm1DetailBoxTitle {
        position: absolute;
        top: 66.13vw;
        left: 0vw;
        font-family: HarmonyOS Sans SC;
        font-size: 20px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0em;

        color: #222449;
      }
      .farm1DetailBoxText {
        width: 100%;
        position: absolute;
        top: 74.67vw;
        left: 0vw;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: justify; /* 浏览器可能不支持 */
        letter-spacing: 0em;

        color: #222449;
      }
      .farm2 {
        position: absolute;
        top: 110.93vw;
        left: 0vw;
        width: 89.33vw;
        height: 50.13vw;
      }
      .farm2DetailBoxTitle {
        position: absolute;
        top: 166.4vw;
        left: 0vw;
        font-family: HarmonyOS Sans SC;
        font-size: 20px;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0em;

        color: #222449;
      }
      .farm2DetailBoxText {
        width: 100%;
        position: absolute;
        top: 174.9vw;
        left: 0vw;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        text-align: justify; /* 浏览器可能不支持 */
        letter-spacing: 0em;

        color: #222449;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
  }
  .csbCan {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 1200px) {
    .csbCan {
      .canInside {
        width: 1200px;
        box-sizing: border-box;
        .canInsideTitle {
          padding: 80px 0 40px 0;
          font-family: HarmonyOS Sans SC;
          font-size: 40px;
          font-weight: 900;
          line-height: 40px;
          text-align: center;
          letter-spacing: 0em;

          color: #333333;
        }
        .canInsideText {
          font-family: HarmonyOS Sans SC;
          font-size: 16px;
          font-weight: 300;
          line-height: 32px;
          letter-spacing: 0px;

          color: #333333;
        }
        .canPicArea {
          padding: 0 0 0 70px;
          box-sizing: border-box;
          // .canPicAreaItem {
          //   display: flex;
          //   flex-direction: column;
          // }
          .canPicAreaM {
            display: none;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .csbCan {
      .canInside {
        .canInsideTitle {
          padding: 10.6vw 0 5.3vw 5.3vw;
          font-family: HarmonyOS Sans SC;
          font-size: 20px;
          font-weight: 900;
          line-height: 20px;
          // text-align: center;
          letter-spacing: 0em;

          color: #333333;
        }
        .canInsideText {
          padding: 0 5.3vw 0 5.3vw;
          box-sizing: border-box;
          font-family: HarmonyOS Sans SC;
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: 0px;

          color: #333333;
        }
        .canPicArea {
          // padding: 0 0 0 70px;
          // box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          // .canPicAreaItem {
          //   display: flex;
          //   flex-direction: column;
          // }
          .canPicAreaM {
            width: 67.2vw;
            // height: 2583px;
          }
          .canPicAreaPC {
            display: none;
          }
        }
      }
    }
  }
  .softwareBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .softwareInside {
      width: 1200px;
      padding: 80px 0 80px 0;
      box-sizing: border-box;
      .softwareSupport {
        margin-bottom: 80px;
        .softwareSupportTitle {
          font-family: HarmonyOS Sans SC;
          font-size: 40px;
          font-weight: 900;
          line-height: 40px;
          text-align: center;
          letter-spacing: 0em;
          margin-bottom: 40px;
          color: #333333;
        }
        .softwareSupportText {
          font-family: HarmonyOS Sans SC;
          font-size: 16px;
          font-weight: 300;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0px;
          margin-bottom: 40px;
          color: #333333;
        }
        .softwareSupportPicBox {
          display: flex;
          justify-content: space-between;
          .iphone {
            img {
              margin-right: 48px;
            }
          }
          .pc {
          }
        }
      }
      .softwareFunction {
        width: 100%;

        .softwareFunctionTitle {
          font-family: HarmonyOS Sans SC;
          font-size: 40px;
          font-weight: 900;
          line-height: 40px;
          text-align: center;
          letter-spacing: 0em;

          color: #333333;
          margin: 0px 0 60px 0;
        }
        .softwareFunctionBoxWrapper {
          display: flex;
          justify-content: space-around;
          .softwareFunctionBox {
            display: flex;
            flex-direction: column;
            // justify-content: space-between;
            align-items: center;
            height: 96px;
            .functionText {
              margin-top: 32px;
              font-family: 苹方-简;
              font-size: 16px;
              font-weight: normal;
              line-height: 16px;
              text-align: center;
              letter-spacing: 0em;

              color: #666666;
            }
          }
        }
      }
      .softwareVideo {
        margin-top: 64px;
        display: grid;
        grid-gap: 60px;
        grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
        grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */
        .videoList {
          position: relative;
          .playBtn {
            z-index: 1;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
          }
          #fram_box {
            width: 100%;
            height: 100%;
          }
        }
        @media only screen and (min-width: 1200px) {
          .videoList-m {
            display: none;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .softwareBox {
      .softwareInside {
        width: 100%;
        padding: 4.1vw 0 4.1vw 0;
        box-sizing: border-box;
        .softwareSupport {
          .softwareSupportTitle {
            padding: 0 5.33vw 0 5.33vw;
            font-family: HarmonyOS Sans SC;
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            letter-spacing: 0em;
            margin-bottom: 20px;
            color: #333333;
          }
          .softwareSupportText {
            padding: 0 5.33vw 0 5.33vw;
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            text-align: left;
            letter-spacing: 0px;
            margin-bottom: 20px;
            color: #333333;
          }
          .softwareSupportPicBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            .iphone {
              display: flex;
              justify-content: center;
              img {
                width: 18.53vw;
                height: 37.33vw;
                margin-right: 6.4vw;
                margin-bottom: 6.4vw;
              }
              img:last-child {
                margin-right: 0;
              }
            }
            .pc {
              width: 65.83vw;
              height: 37.33vw;
            }
          }
        }
        .softwareFunction {
          width: 100%;

          .softwareFunctionTitle {
            font-family: HarmonyOS Sans SC;
            font-size: 20px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;

            padding: 0 5.33vw 0 5.33vw;

            margin: 40px 0 30px 0;
          }
          .softwareFunctionBoxWrapper {
            padding: 0 5.33vw 0 5.33vw;
            box-sizing: border-box;
            display: grid;
            grid-gap: auto;
            grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
            grid-template-rows: repeat(2, auto); /* 总共两行，自适应高度 */
            .softwareFunctionBox {
              display: flex;
              flex-direction: column;
              // justify-content: space-between;
              align-items: center;
              height: 100px;
              .functionText {
                margin-top: 16px;

                font-size: 14px;
                font-weight: normal;
                line-height: 16px;
                text-align: center;
              }
            }
          }
        }
        .softwareVideo {
          width: 100%;
          margin-top: 0px;
          display: grid;
          grid-gap: 0;
          grid-template-columns: repeat(3, auto); /* 总共三列，占据剩余空间的比例相等 */
          grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */

          .videoList {
            display: none;
          }
          .videoList-m {
            position: relative;
            .coverPic {
            }
            .playBtn {
              z-index: 1;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate3d(-50%, -50%, 0);
            }
          }
        }
      }
    }
  }

  .advantage {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f3ee;
    .advantageInside {
      width: 1200px;
      padding: 80px 0 80px 0;
      box-sizing: border-box;
      display: flex;
      img {
        width: 440px;
        height: 640px;
        margin-right: 80px;
      }
      .advantageDetail {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .advantageDetailBox {
          // margin: 0 0 80px 0;
          .advantageDetailBoxTitle {
            font-family: HarmonyOS Sans SC;
            font-size: 32px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0em;
            margin-bottom: 16px;
            color: #333333;
          }
          .advantageDetailBoxText {
            font-family: 苹方-简;
            font-size: 14px;
            font-weight: normal;
            line-height: 24px;
            text-align: justify; /* 浏览器可能不支持 */
            letter-spacing: 0em;

            color: #333333;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .advantage {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f2f3ee;
      .advantageInside {
        // width: 1200px;
        padding: 4.1vw 0 4.1vw 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-right: 0;
          margin-bottom: 4.1vw;
          width: 58.7vw;
          height: 85.3vw;
        }
        .advantageDetail {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .advantageDetailBox {
            padding: 0 5.33vw 5.33vw 5.33vw;
            .advantageDetailBoxTitle {
              font-size: 20px;
              font-weight: 300;
              line-height: 40px;
              margin-bottom: 6px;
            }
            .advantageDetailBoxText {
              font-size: 14px;
              font-weight: 300;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
  }
}
</style>